export default class {
    constructor() {
        this.cv = '#js-bnr-cv';
        this.close = '#js-bnr-cv__close';
        this.activeClass = 'is-active';
        // window.addEventListener('scroll', () => {
        //     this.cvFadeIn();      
        // })
        this.cvClose();
    }
    // cvFadeIn() {
    //     const cv = document.querySelector(this.cv);
    //     const height = window.innerHeight;
    //     const scrollY = window.pageYOffset;
    //     if(cv) {
    //         if( scrollY > height) {
    //             cv.classList.add(this.activeClass);
    //         } else {
    //             cv.classList.remove(this.activeClass);
    //         }
    //     }
    // }
    cvClose() {
        const cv = document.querySelector(this.cv);
        if(cv) {
            const close = cv.querySelector(this.close);
            if(close) {
                close.addEventListener('click', () => {
                    cv.style.display = 'none';
                })
            }
        }
    }
}