import "../scss/style.scss";

/**
 * @babel/polyfill の利用
 * ※ PromiseやArray.includes()など、構文ではない機能的な仕様を利用するために必要
 */
import "@babel/polyfill";

if ('NodeList' in window && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

import lazysizes from "lazysizes"
import unveil from "lazysizes/plugins/unveilhooks/ls.unveilhooks.min.js"

import switchViewport from "./lib/switchViewport";
import ResponsiveImages from "./lib/ResponsiveImages";
import Accordion from "./lib/Accordion"
import Slider from "./lib/Slider";
import Headroom from "./lib/Headroom";
import Lightbox from "./lib/lightbox";
import stickySide from "./lib/stickySide";
import SmoothScroll from "./lib/SmoothScroll";
import Drawer from "./lib/Drawer";
import ScrollTrigger from "./lib/ScrollTrigger";
import Entry from "./lib/Entry";
import Loading from "./lib/Loading";
import Form from "./lib/Form";
import Tab from "./lib/Tab";
// import Pin from "./lib/Pin";
import CV from "./lib/CV";

new switchViewport();
new Slider();
new ResponsiveImages();
new Headroom();
new Lightbox();
new stickySide();
new SmoothScroll();
new Accordion();
new Drawer();
new Entry();

new ScrollTrigger();
new Loading();
new Form();
new Tab();
// new Pin();
new CV();