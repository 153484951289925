import Swiper from'swiper/bundle';
import 'swiper/css/bundle';

export default class {
    constructor() {
        this.recommendSlider();
    }
    recommendSlider(){
        const recommendSlider = '#js-slider-case';
        new Swiper (recommendSlider,{
            effect:'slide',
            speed:1000,
            easing: "easeInOut",
            // autoplay: {
            //     delay: 4000
            // },
            slidesPerView: 1.125,
            spaceBetween: 20,
            pagination: {
                el: "#js-slider-case__pagination",
                clickable: true,
            },
            navigation: {
                nextEl: "#js-slider-case__next",
                prevEl: "#js-slider-case__prev"
            },
            breakpoints: {
                768: {
                    slidesPerView: 2.125,
                    spaceBetween: 25,
                },
                1330: {
                    slidesPerView: 2.125,
                    // slidesPerView: 2.125,
                    spaceBetween: 30,
                },
            }
        })
    }
}